const intro = document.querySelector('.intro')
const video = document.querySelector('video')
const overlay = document.querySelector('.intro-overlay')
const text = document.querySelector('.intro h1')
//END section
const section = document.querySelector('section')
const end = section.querySelector('h2')

//DETECT BROWSER
let isMobile
let windowsHeight
if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  // true for mobile device
  isMobile = true
  windowsHeight = window.innerHeight*1.1
}else{
  // false for not mobile device
  isMobile = false
  windowsHeight = window.innerHeight*1.2
}

//SCROLLMAGIC
const controller = new ScrollMagic.Controller()
video.load()

//Scenes
let scene = new ScrollMagic.Scene({
    duration: windowsHeight,
    triggerElement: intro,
    triggerHook: 0
})
/* .addIndicators() */
.setPin(intro)
.addTo(controller)

/* Overlay Animation */
const overlayAnim = TweenMax.fromTo(overlay, {opacity: 0}, {opacity: 0.4})
let scene2 = new ScrollMagic.Scene({
  duration: '40%',
  triggerElement: intro,
  offset: windowsHeight/3,
  triggerHook: 0
})
/* .addIndicators() */
.setTween(overlayAnim)
.addTo(controller)

//Text animation
const textAnim = TweenMax.fromTo(text, {opacity: 0}, {opacity: 1})
let scene3 = new ScrollMagic.Scene({
    duration: '40%',
    triggerElement: intro,
    offset: windowsHeight/3,
    triggerHook: 0
})
/* .addIndicators() */
.setTween(textAnim)
.addTo(controller)


//Video animation
let accelamount = 0.1
let scrollpos = 0
let delay = 0

scene.on('update', e => {
    scrollpos = e.scrollPos / 1000
    let progress = scene.progress()
})

setInterval(() => {
    delay += (scrollpos - delay) * accelamount
    video.currentTime = scrollpos
}, 60)


/* Swiper */
const swiper = new Swiper('.swiper', {
    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });

const swiperInfo = new Swiper('.sm-info-swiper', {
  // Optional parameters
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination.info',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next.info',
    prevEl: '.swiper-button-prev.info',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});